:root {
  @apply bg-gray-100;
}

:root.dark {
  @apply bg-black;
}

html, body, #root, .App {
  @apply min-h-screen;
}

body {
  @apply overflow-x-hidden;
}

.App {
  @apply bg-gray-100 dark:bg-black min-h-screen;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ToolBar {
  @apply bg-gray-100 dark:bg-gray-800 shadow-xl fixed top-0 left-1/2 transform -translate-x-1/2 z-50 flex justify-center items-center rounded-2xl w-[90vw] max-w-screen-md mx-auto p-4;
}

/* Add this new class for smooth transitions */
.navbar-transition {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.App-header {
  @apply text-black dark:text-white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
